import { Country } from "@shared/dto/countries";
import { Thread } from "@shared/dto/thread";
import { defaultUserSettings, UserSettings } from "@shared/dto/user-settings";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { DateRange } from "react-day-picker";
import { atom } from "recoil";

export const debugModeGlobal = atom<boolean>({
  key: "debugModeGlobal",
  default: false,
});

//////////////
// Language //
//////////////
type langType = "en" | "it" | "fr" | "de";
type langDictionaryType = {
  [key: string]: {
    [lang in langType]: string;
  };
};
export const available_Languages: {
  code: langType;
  name: string;
}[] = [
    { code: "de", name: "Deutsch" },
    { code: "fr", name: "Français" },
    { code: "it", name: "Italiano" },
    { code: "en", name: "English" },
  ];
export const languageSettingGlobal = atom<langType>({
  key: "languageSettingGlobal",
  default: "de",
});

export type {
  langDictionaryType,
  langType,
};

//////////////////
// User Setting //
//////////////////
export const userSettingGlobal = atom<UserSettings>({
  key: "userSettingGlobal",
  default: defaultUserSettings
});


///////////
// Input //
///////////
export const questionInputGlobal = atom<string>({
  key: "questionInputGlobal",
  default: "",
});

export const followUpQuestionInputGlobal = atom<string>({
  key: "followUpQuestionInputGlobal",
  default: "",
});

export interface FileExport {
  file: File;
  name: string;
  content: string;
  status: string;
}

export const filesInputGlobal = atom<FileExport[]>({
  key: "filesInputGlobal",
  default: [],
});

export const limitToInternalDataInputGlobal = atom<boolean>({
  key: "limitToInternalDataInputGlobal",
  default: false,
});

////////////////////
// Laoding States //
////////////////////

export const isLoadingTextGlobal = atom<boolean>({
  key: "isLoadingTextGlobal",
  default: false,
});

export const isLoadingSourcesGlobal = atom<boolean>({
  key: "isLoadingSourcesGlobal",
  default: false,
});

export const anonModelLoaded = atom<boolean>({
  key: "anonMddelLoaded",
  default: false,
});


//////////////
// Threads  //
//////////////
export const emptyThread: Thread = {
  interactions: [],
  id: "",
  date: new Date(),
};
export const selectedThreadGlobal = atom<Thread>({
  key: "selectedThreadGlobal",
  default: emptyThread,
});

export const selectedInteractionIndexGlobal = atom<number>({
  key: "selectedInteractionIndexGlobal",
  default: 0,
});


/////////////
// History //
/////////////
export const historyThreadsGlobal = atom<Thread[]>({
  key: "historyThreadsGlobal",
  default: [],
});

export const historyDefaultDateRange: DateRange = {
  from: startOfDay(subDays(new Date(), 7)),
  to: endOfDay(new Date()),
};
export const historyDateRangeGlobal = atom<DateRange>({
  key: "historyDateRangeGlobal",
  default: historyDefaultDateRange,
});

export const historySelectedGroupsGlobal = atom<string[]>({
  key: "historySelectedGroupsGlobal",
  default: [],
});
export const historySelectedEmailsGlobal = atom<string[]>({
  key: "historySelectedEmailsGlobal",
  default: [],
});

