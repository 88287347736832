import { getUserAndGroupPermissions } from "../db/usersandhistory/src/utils_users_groups";
import { GroupPermissions, MainPermissions, UserPermissions } from "./types";

// Add caching wrapper
const cache = new Map<
   string,
   {
      data: Awaited<ReturnType<typeof getUserAndGroupPermissions>>;
      timestamp: number;
   }
>();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getCachedUserAndGroupPermissions = async (email: string) => {
   const now = Date.now();
   const cached = cache.get(email);

   if (cached && now - cached.timestamp < CACHE_DURATION) {
      return cached.data;
   }

   const data = await getUserAndGroupPermissions(email);
   cache.set(email, { data, timestamp: now });
   return data;
};

export const mergePermissions = (
   groupPermissions: GroupPermissions,
   userPermissions: UserPermissions
): MainPermissions => {
   return {
      ...groupPermissions.mainPermissions,
      ...userPermissions.mainPermissions,
   };
};

export function mergeWithDefault<T>(defaultObj: T, obj: any): T {
   if (typeof defaultObj !== "object" || defaultObj === null) {
      return obj !== undefined && typeof obj === typeof defaultObj ? obj : defaultObj;
   }
   const defaultObjRecord = defaultObj as Record<string, any>;
   return Object.keys(defaultObjRecord).reduce((result, key) => {
      if (obj && key in obj) {
         result[key] = mergeWithDefault(defaultObjRecord[key], obj[key]);
      } else {
         result[key] = defaultObjRecord[key];
      }
      return result;
   }, {} as any);
}

/////////
// Dev //
/////////

export const canUseAccountManager = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useAccountManager === true;
};

export const canTriggerStatsComputation = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).triggerStatsComputation === true;
};

export const canReadSharedLinks = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).canReadThreadSharedLinks === true;
};

export const canChangeCountry = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).canChangeCountry === true;
};

export const canGetAilegisDailyBanner = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).getAilegisDailyBanner === true;
}

export const canGetNameIdeaBanner = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).getNameIdeaBanner === true;
}

export const canGetDemoBanner = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).getDemoBanner === true;
}

export const canGetDemoBannerWithEmailRequest = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).getDemoBannerWithEmailRequest === true;
}

//////////////
// Question //
//////////////
export const canUseQuestion = async (email: string): Promise<boolean> => {
   // No hardcoded users or domains; return true.
   return true;
};

export const canUseFollowUpQuestion = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   const perms = mergePermissions(group, user);
   return perms.useFollowUpQuestion === true;
};

export const canUseQuestionDailyLimit = async (email: string): Promise<{ perm: boolean; value: number }> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   const perms = mergePermissions(group, user);
   return {
      perm: perms.useQuestionDailyLimit === true,
      value: perms.questionDailyLimit,
   };
};

export const canUseQuestionWeeklyLimit = async (email: string): Promise<{ perm: boolean; value: number }> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   const perms = mergePermissions(group, user);
   return {
      perm: perms.useQuestionWeeklyLimit === true,
      value: perms.questionWeeklyLimit,
   };
};

export const canUseQuestion_Files = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useQuestionFiles === true;
};

export const canUseQuestion_FastMode = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useQuestionFastMode === true;
};

export const canUseQuestion_Anonimisation = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useQuestionAnonimisation === true;
};

export const canUseQuestion_LocalStorageRateLimit = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useQuestionLocalStorageRateLimit === true;
}

/////////////
// History //
/////////////
export const canUseHistory = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useHistory === true;
};

export const canUseHistory_AllColumn = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useHistoryAllColumn === true;
};

export const canUseHistory_FilterGroupsPersons = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useHistoryFilterGroupsPersons === true;
};

export const canUseHistory_FilterTime = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useHistoryFilterTime === true;
};

////////////////////
// Coverage Check //
////////////////////
export const canUseCoverageCheck = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useCoverageCheck === true;
};

export const canSeeDocumentView = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).seeDocumentView === true;
};

///////////////////
// Anonimisation //
///////////////////
export const canUseAnonimisation = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useAnonimisation === true;
};

///////////////////
// Internal data //
///////////////////
// TODO: Use same one function for all documents of an organization?
export const canUseFAQ = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useFAQ === true;
};



// `organization` is the name of the organization which the templates are from.
// It is NOT the name of the organization of the user.
// Don't forget to await this function.
export const canUseOrganizationTemplates = async (email: string, organization: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   if (organization === "Dextra") {
      return mergePermissions(group, user).useDextraTemplates === true;
   } else if (organization === "Ailegis") {
      return mergePermissions(group, user).useAilegisTemplates === true;
   }
   return false;
};

export const canUseDextraNutshells = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useDextraNutshells === true;
};

export const canUseOtherDextraInternalDocuments = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useOtherDextraInternalDocuments === true;
};

export const canLimitToInternalData = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).limitToInternalData === true;
};

export const canUseVaudoiseDictionary = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useVaudoiseDictionary === true;
}

// Higher token limits, e.g. lower for students
export const canUseHighCompute = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useHighCompute === true;
}

// Whether to instruct Ailegis about user being lawyer (for law firms)
export const useLawFirmLawyerPrompt = async (email: string): Promise<boolean> => {
   const { user, group } = await getCachedUserAndGroupPermissions(email);
   return mergePermissions(group, user).useLawFirmLawyerPrompt === true;
}
