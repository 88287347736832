"use client";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../dashboard/demo";
import { available_Languages, langType } from "../dashboard/state";
import AuthenticationForm from "./loginform";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { CirclePlus, PlusCircle, History, User } from "lucide-react";
import { Nav } from "@/components/nav";
import { Textarea } from "@/components/ui/textarea";
import Module from "module";
import { de, fr } from "date-fns/locale";
import { sign } from "crypto";

const LanguagePicker = ({
  lang,
  setLang,
}: {
  lang: langType;
  setLang: (v: langType) => void;
}) => {
  return (
    <div className=" w-fit">
      <Popover>
        <PopoverTrigger>
          <div className=" text-sm p-2 rounded hover:bg-neutral-100 hover:text-ailegis-950 h-9 border ">
            {lang.toUpperCase()}
          </div>
        </PopoverTrigger>
        <PopoverContent side="right" className=" w-fit mb-2">
          <div>
            {available_Languages.map((l, idx) => {
              return (
                <div
                  key={idx}
                  className={cn(
                    " cursor-pointer hover:underline rounded px-2",
                    lang === l.code ? " bg-ailegis-200" : ""
                  )}
                  onClick={() => {
                    setLang(l.code);
                  }}
                >
                  {l.name}
                </div>
              );
            })}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const dictionary = {
  welcomeMessage: {
    en: "AI-powered legal advice for modern legal professionals",
    de: "KI-gestützte Rechtsberatung für moderne JuristInnen",
    fr: "Conseil juridique assisté par IA pour les juristes modernes",
    it: "Consulenza legale basata sull'IA per giuristi moderni",
  },
  loginTitle: {
    en: "Login with existing account or try the demo",
    de: "Login mit existierendem Account oder Demo ausprobieren",
    fr: "Connexion avec un compte existant ou essayez la démo",
    it: "Accedi con un account esistente o prova la demo",
  },

  loginTitleLogin: {
    en: (
      <div className="flex flex-col items-start text-left">
        <div>Welcome Back !</div>
        <div className="text-sm font-normal text-neutral-500">
          Use Ailegis to complete your legal research with ease.
        </div>
      </div>
    ),
    de: (
      <div className="flex flex-col items-start text-left">
        <div>Willkommen zurück!</div>
        <div className="text-sm font-normal text-neutral-500">
          Nutze Ailegis und erledige juristische Recherchen mühelos.
        </div>
      </div>
    ),
    fr: (
      <div className="flex flex-col items-start text-left">
        <div>Bon retour !</div>
        <div className="text-sm font-normal text-neutral-500">
          Utilisez Ailegis pour mener à bien vos recherches juridiques en toute
          simplicité.
        </div>
      </div>
    ),
    it: (
      <div className="flex flex-col items-start text-left">
        <div>Bentornato!</div>
        <div className="text-sm font-normal text-neutral-500">
          Usa Ailegis per completare le tue ricerche legali con facilità.
        </div>
      </div>
    ),
  },

  loginTitleRegister: {
    en: (
      <div className="flex flex-col items-start text-left">
        <div>Welcome to Ailegis !</div>
        <div className="text-sm font-normal text-neutral-500">
          Sign up to start doing legal research with ease.
        </div>
      </div>
    ),
    de: (
      <div className="flex flex-col items-start text-left">
        <div>Willkommen bei Ailegis!</div>
        <div className="text-sm font-normal text-neutral-500">
          Registriere dich und erledige juristische Recherchen mühelos.
        </div>
      </div>
    ),
    fr: (
      <div className="flex flex-col items-start text-left">
        <div>Bienvenue sur Ailegis !</div>
        <div className="text-sm font-normal text-neutral-500 ">
          Inscrivez-vous pour commencer à effectuer des recherches juridiques en
          toute simplicité.
        </div>
      </div>
    ),
    it: (
      <div className="flex flex-col items-start text-left">
        <div>Benvenuto su Ailegis!</div>
        <div className="text-sm font-normal text-neutral-500">
          Iscriviti per iniziare a fare ricerche legali con facilità.
        </div>
      </div>
    ),
  },
  signInButton: {
    en: "Sign In with ",
    de: "Anmelden mit ",
    fr: "Se connecter avec ",
    it: "Accedi con ",
  },
  signUpButton: {
    en: "Sign Up with",
    de: "Registrieren mit",
    fr: "S'inscrire avec",
    it: "Iscriviti con",
  },
  noAccountText: {
    en: "No account?",
    de: "Kein Konto?",
    fr: "Pas de compte?",
    it: "Nessun account?",
  },
  demoLinkText: {
    en: "Try demo",
    de: "Demo ausprobieren",
    fr: "Essayer la démo",
    it: "Prova la demo",
  },
  continueButton: {
    en: "Continue",
    de: "Weiter",
    fr: "Continuer",
    it: "Continua",
  },
  dontShowMessage: {
    en: "Don't show message next time",
    de: "Diese Nachricht beim nächsten Mal nicht anzeigen",
    fr: "Ne plus afficher ce message",
    it: "Non mostrare più questo messaggio",
  },
  cookieSettings: {
    en: "Cookie settings",
    de: "Cookie-Einstellungen",
    fr: "Paramètres des cookies",
    it: "Impostazioni dei cookie",
  },
  cookieBannerMessage: {
    en: "We use cookies to remember your preferences, like language, and to help us improve by understanding how people use our website.",
    de: "Wir verwenden Cookies, um uns Ihre Einstellungen wie die Sprache zu merken und um uns zu helfen, indem wir verstehen, wie unsere Website genutzt wird.",
    fr: "Nous utilisons des cookies pour mémoriser vos préférences, comme la langue, et pour nous aider à comprendre comment notre site est utilisé.",
    it: "Utilizziamo i cookie per ricordare le tue preferenze, come la lingua, e per aiutarci a capire come viene utilizzato il nostro sito web.",
  },
  allowAll: {
    en: "Allow All",
    de: "Alle zulassen",
    fr: "Tout autoriser",
    it: "Permetti tutto",
  },
  userPreferences: {
    en: "User Preferences",
    de: "Benutzereinstellungen",
    fr: "Préférences utilisateur",
    it: "Preferenze utente",
  },
  usageAndMonitoring: {
    en: "Usage and Bug Monitoring",
    de: "Nutzung und Fehlerüberwachung",
    fr: "Surveillance de l'utilisation et des bugs",
    it: "Monitoraggio dell'utilizzo e dei bug",
  },
  didyouknow: {
    en: "Did you know?",
    de: "Schon gewusst?",
    fr: "Saviez-vous que?",
    it: "Sapevi che?",
  },
  didyouknowText: {
    en: "Ailegis is the leading AI for legal research in Switzerland. Some organizations that use Ailegis daily:",
    de: "Ailegis ist die führende KI für die Recherche im Schweizer Recht. Einige Organisationen, die Ailegis täglich nutzen:",
    fr: "Ailegis est le principal IA pour la recherche juridique en Suisse. Certaines organisations qui utilisent Ailegis quotidiennement:",
    it: "Ailegis è il principale AI per la ricerca legale in Svizzera. Alcune organizzazioni che utilizzano Ailegis quotidianamente:",
  },
  alreadyhaveanaccount: {
    en: "Already have an account?",
    de: "Bereits ein Konto?",
    fr: "Déjà un compte?",
    it: "Hai già un account?",
  },
  donthaveanaccountyet: {
    en: "Don't have an account yet?",
    de: "Noch kein Konto?",
    fr: "Pas encore de compte?",
    it: "Non hai ancora un account?",
  },
};

const mockupBg = () => {
  return (
    <div className="absolute top-0 h-screen w-screen -z-20">
      {/* Blurred and non-clickable overlay */}
      <div className="absolute inset-0 bg-white/30 blur-sm backdrop-blur-md pointer-events-none">
        <div className="absolute h-screen w-screen top-0 flex flex-row opacity-50">
          <div className="bg-ailegis-950 border-r border-black box-border px-2 flex md:flex-col flex-wrap text-white justify-between">
            <div className="flex md:flex-col flex-row">
              <div className="h-[52px] items-center justify-center mx-4 md:m-0 hidden md:flex">
                AI
              </div>
              <Nav
                links={[
                  {
                    title: "",
                    label: <></>,
                    icon: CirclePlus,
                    href: "/login",
                    onClick: () => {},
                  },
                ]}
                isCollapsed={false}
              />
              <Nav
                isCollapsed={false}
                links={[
                  {
                    title: "",
                    label: <></>,
                    icon: History,
                    href: "/dashboard/history",
                  },
                ]}
              />
            </div>

            <div className="flex md:flex-col flex-row">
              <Nav
                links={[
                  {
                    title: "",
                    label: <></>,
                    icon: User,
                    href: "",
                    onClick: () => {},
                  },
                ]}
                isCollapsed={false}
              />

              <div className="h-[52px] items-center justify-center mx-4 md:m-0 hidden md:flex">
                DE
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 w-full h-full">
            <div className="border-r bg-neutral-200 border-neutral-200">
              <div className="border-b border-neutral-700  p-4 font-semibold">
                Question
              </div>
              <div className="p-6">
                <div className="">
                  <Textarea
                    value={"This is a queytin fdksjk jk sdfjk sfdjk sfd ?"}
                    onChange={() => {}}
                  />
                </div>

                <div className=" font-semibold py-3">Filter Settings</div>

                <div className="flex flex-col gap-2">
                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-green-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Dsdfsoc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-yellow-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosaddhsjf ijfs fdsij fdsij
                      ifsjdikosdak akd sookasdoadskosad kasodk aso dkaodkaokdsk
                      oaskodkoasdoksako doksadkoako sdoak dsok aksod kao sdk
                      oako sdko adko kao sdok aoksdokasokdkoasdko a
                      kodskoasdkoakosd koaskodok asdokaok sdkoaskdokoasdk
                      oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-yellow-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-green-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Titlsdfsdfe Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-green-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sooka dkfj jfsdjfsdi
                      jij isdfji jsfijfjisjfsjdi sdoadskosad kasodk aso
                      dkaodkaokdsk oaskodkoasdoksako doksadkoako sdoak dsok
                      aksod kao sdk oako sdko adko kao sdok aoksdokasokdkoasdko
                      a kodskoasdkoakosd koaskodok asdokaok sdkoaskdokoasdk
                      oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-blue-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Titlesdf Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-yellow-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Titlfsdfsde Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasvsivsc jvsic ivscj viodk aso dkaodkaokdsk
                      oaskodkoasdoksako doksadkoako sdoak dsok aksod kao sdk
                      oako sdko adko kao sdok aoksdokasokdkoasdko a
                      kodskoasdkoakosd koaskodok asdokaok sdkoaskdokoasdk
                      oakodkoadoka oksd
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="border-b border-neutral-700 p-4 font-semibold">
                Answer
              </div>
              <div className="p-4"></div>

              <div className=" flex flex-col gap-6 p-6">
                <div>
                  Oui, vous pouvez toujours faire une réclamation{" "}
                  <span className=" bg-ailegis-300">xxx</span> pour votre
                  accident survenu pendant la pause déjeuner, à condition que
                  l'accident se soit produit sur le lieu de travail ou dans la
                  zone de danger liée à votre activité professionnelle. Selon
                  l'article 7 alinéa 1 lettre b de la Loi sur
                  l'assurance-accidents (LAA), un accident est considéré comme
                  professionnel lorsqu'il survient "au cours d’une interruption
                  de travail, de même qu’avant ou après le travail, lorsqu’il se
                  trouve, à bon droit, au lieu de travail ou dans la zone de
                  danger liée à son activité professionnelle" (Art. 7 al. 1 let.
                  b LAA).
                </div>
                <div className="bg-ailegis-50 p-4 border border-ailegis-500 rounded">
                  Ainsi, si l'accident s'est produit sur le lieu de travail ou{" "}
                  <span className=" bg-ailegis-300">xxx</span> dans une zone
                  directement liée à votre activité professionnelle, il peut
                  être déclaré comme accident professionnel. En revanche, si
                  l'accident s'est produit en dehors de ces zones, par exemple
                  en dehors du site de la{" "}
                  <span className=" bg-ailegis-300">xxx</span> société, il
                  pourrait être considéré comme un accident non professionnel.
                </div>

                <div>
                  Il est également important de noter que les travailleurs à
                  temps partiel travaillant au moins huit heures par semaine
                  sont couverts contre les accidents non professionnels (Art. 13
                  al. 1 OLAA).
                </div>

                <div>
                  Pour les éviter des frais juridiques,
                  <span className=" bg-ailegis-300">xxx</span> assurez-vous de
                  documenter<span className=" bg-ailegis-300">xxx</span>{" "}
                  précisément les circonstances de l'accident et de consulter
                  votre employeur ou votre assureur pour plus de détails sur la
                  couverture d'assurance applicable à votre cas.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function AuthenticationPage() {
  const [lang, setLang] = useState<langType>(() => {
    return getLocalStorageItem("preferredLang", "de");
  });
  const [loginform, setLoginform] = useState(<></>);
  const [isnewuser, setIsnewuser] = useState(true);

  useEffect(() => {
    setLocalStorageItem("preferredLang", lang);
    AuthenticationForm({
      text: isnewuser
        ? dictionary.signUpButton[lang]
        : dictionary.signInButton[lang],
    }).then((f) => {
      setLoginform(f);
    });
  }, [lang, isnewuser]);

  const [userPrefCookie, setUserPrefCookie] = useState(true);
  const [monitoringCookie, setMonitoringCookie] = useState(true);

  useEffect(() => {
    setUserPrefCookie(getLocalStorageItem("userPrefCookie", "yes") === "yes");
    setMonitoringCookie(
      getLocalStorageItem("monitoringCookie", "yes") === "yes"
    );
  }, []);

  useEffect(() => {
    if (window.clarity) {
      window.clarity("consent", monitoringCookie);
    }
  }, [userPrefCookie, monitoringCookie]);

  return (
    <>
      {mockupBg()}
      <div className="p-1 w-screen h-screen grid place-items-center">
        <div className="max-h-[800px] max-w-[1600px] flex place-items-center shadow-2xl border rounded-md overflow-clip bg-ailegis-950">
          {/**Login */}
          <div className="p-1 sm:p-8 bg-white grid place-items-center h-full">
            <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
              <div className="flex flex-row justify-between w-full">
                <div className="font-semibold"> AILegis </div>

                <div>
                  {" "}
                  <LanguagePicker lang={lang} setLang={setLang} />
                </div>
              </div>

              <div>
                <div className="flex flex-col space-y-2 text-center">
                  <h1 className="text-2xl font-bold tracking-tight">
                    {isnewuser ? (
                      <>{dictionary.loginTitleRegister[lang]}</>
                    ) : (
                      <>{dictionary.loginTitleLogin[lang]}</>
                    )}
                  </h1>
                  <div className=" min-h-[300px] py-9">{loginform}</div>

                  {/*

                  <Dialog>
                    <DialogTrigger>
                      <div className=" bg-ailegis-50 border rounded border-ailegis-500 cursor-pointer hover:bg-ailegis-100 text-sm">
                        {dictionary.cookieSettings[lang]}
                      </div>
                    </DialogTrigger>
                    <DialogContent>
                      <div className="grid place-items-center">
                        <div className="max-w-[60vw] max-h-[60vh] rounded p-10 overflow-scroll no-scrollbar">
                          <div className="text-xl font-semibold">
                            {dictionary.cookieSettings[lang]}
                          </div>
                          <div className="text-sm mb-4 sm:mb-0">
                            {dictionary.cookieBannerMessage[lang]}
                          </div>

                          <div className="pt-4">
                            <div className="flex flex-row gap-2 items-center">
                              <Checkbox
                                checked={userPrefCookie && monitoringCookie}
                                onCheckedChange={(v) => {
                                  setUserPrefCookie(v);
                                  setMonitoringCookie(v);
                                  setLocalStorageItem(
                                    "userPrefCookie",
                                    v ? "yes" : "no"
                                  );
                                  setLocalStorageItem(
                                    "monitoringCookie",
                                    v ? "yes" : "no"
                                  );
                                }}
                              />
                              {dictionary.allowAll[lang]}
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                              <Checkbox
                                checked={userPrefCookie}
                                onCheckedChange={(v) => {
                                  setUserPrefCookie(v);
                                  setLocalStorageItem(
                                    "userPrefCookie",
                                    v ? "yes" : "no"
                                  );
                                }}
                              />
                              {dictionary.userPreferences[lang]}
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                              <Checkbox
                                checked={monitoringCookie}
                                onCheckedChange={(v) => {
                                  setMonitoringCookie(v);
                                  setLocalStorageItem(
                                    "monitoringCookie",
                                    v ? "yes" : "no"
                                  );
                                }}
                              />
                              {dictionary.usageAndMonitoring[lang]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
*/}

                  <div
                    className="text-sm text-ailegis-600 cursor-pointer hover:underline"
                    onClick={() => {
                      setIsnewuser(!isnewuser);
                    }}
                  >
                    {isnewuser ? (
                      <> {dictionary.alreadyhaveanaccount[lang]} </>
                    ) : (
                      <> {dictionary.donthaveanaccountyet[lang]} </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/**Logos */}
          <div className="relative hidden max-w-[400px] h-full flex-col sm:p-10 lg:flex dark:border-r text-white bg-ailegis-950">
            <div className="flex flex-col gap-6 justify-between h-full overflow-clip m-6">
              <div className="h-full grid place-items-center relative  ">
                <div className="">
                  <div className=" font-semibold">
                    {dictionary.didyouknow[lang]}
                  </div>
                  <div className=" py-2 text-sm">
                    {dictionary.didyouknowText[lang]}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 w-full justify-between filter grayscale brightness-200">
                <div className="p-2 rounded h-[70px]">
                  <img
                    src="/logo/federal.svg"
                    className="h-full w-full object-contain filter grayscale"
                  />
                </div>
                <div className="p-2 rounded h-[80px]">
                  <img
                    src="/logo/vaudoise.png"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
                <div className="p-2 rounded h-[80px]">
                  <img
                    src="/logo/swisslegal.png"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
                <div className="p-4 rounded h-[70px]">
                  <img
                    src="/logo/dnp.svg"
                    className="h-full w-full object-contain filter grayscale"
                  />
                </div>
                <div className="p-4 rounded h-[65px]">
                  <img
                    src="/logo/legalpartner.png"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
                <div className="rounded h-[75px] p-6">
                  <img
                    src="/logo/dextra.svg"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
